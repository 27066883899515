import React, { useState } from 'react';
import { useRouter } from 'next/router';
import styled from '@emotion/styled';
import useIsRestricted from '@hooks/useIsRestricted';
import { usePleaseLogIn } from '@hooks/usePleaseLogIn';
import { useTranslation } from 'react-i18next';
import {
  HomeFilled,
  CalendarFilled,
  QuestionCircleFilled,
  CarryOutFilled,
  ContainerFilled,
  MenuOutlined
} from 'antd/icons';
import type { AntdIconProps } from '@ant-design/icons/lib/components/AntdIcon';
import { Dropdown } from 'antd';
import { useExtremeZoom } from '@hooks/useZoom';
import { useQuery } from '@tanstack/react-query';
import * as actions from '@actions';
import { useDispatch } from 'react-redux';
import useApi from 'vl-common/src/hooks/useApi';

const StyledHeader: any = styled.div`
  z-index: 1000;
  background-color: var(--productPrimary);
  box-shadow: 0 -6px 6px rgba(0, 0, 0, 0.12);
  padding: 0 auto;
  width: 100%;
  margin: auto;
  height: 48px;

  .button-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin: auto;
    max-width: 1482px;
    height: 100%;
    gap: 2em;
  }
  @media (max-width: 1482px) {
    margin: 0;
    padding: 0;
    .button-container {
      gap: 0;
    }
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

const StyledButton = styled.button<{ active: boolean; badge: string | null }>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: ${({ active }) => (active ? '#ffffff' : 'transparent')};
  color: ${({ active }) => (active ? '#000000' : '#ffffff')};
  width: 20%;
  border: 0;
  cursor: pointer;
  font-size: 16px;
  border-top: 4px solid var(--productPrimary);
  padding-bottom: 4px;
  padding-top: 0;

  .nav-icon {
    font-size: 18px;
    color: ${({ active }) => (active ? 'var(--productPrimary)' : '#ffffff')};
    margin-right: 14px;
  }

  ${({ badge }) =>
    badge !== null
      ? `.nav-icon::after {
        content: '${badge}';
        border-radius: 16px;
        background-color: red;
        display: inline-block;
        height: 15px;
        font-size: 10px;
        position: absolute;
        padding: 0 6px;
        top: 88px;
        margin-left: 10px;
        line-height: 16px;
        color: #ffffff;
        font-weight: bold;
      }`
      : ''}
  .nav-text {
    color: ${({ active }) => (active ? 'var(--productPrimary)' : '#ffffff')};
  }
`;

const MenuButton = ({
  name,
  Icon,
  action,
  testid,
  active,
  badge = null,
  restricted = false
}: {
  name: string;
  Icon: React.ComponentType<AntdIconProps>;
  action: () => void;
  testid: string;
  active: boolean;
  badge?: string | null;
  restricted?: boolean;
}) => {
  const isRestricted = useIsRestricted();
  const [, setPleaseLogIn] = usePleaseLogIn();

  const onClickHandler = () => {
    if (restricted && isRestricted) {
      setPleaseLogIn(true);
    } else {
      action();
    }
  };

  return (
    <StyledButton data-testid={testid} onClick={onClickHandler} active={active} badge={badge}>
      <Icon aria-hidden className="nav-icon" />
      <span className="nav-collapsed">{name}</span>
    </StyledButton>
  );
};

export const NavigationHeader = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const { Api } = useApi();
  const { pathname } = router;
  const { t } = useTranslation();
  const [isHamburgerMenuExpanded, setHamburgerMenuExpanded] = useState(false);

  const isExtremeZoom = useExtremeZoom();
  const isRestricted = useIsRestricted();
  const [, setPleaseLogIn] = usePleaseLogIn();

  const { data: allCases = [] } = useQuery({
    queryKey: ['fetchAllCases'],
    queryFn: () =>
      Api.cases.getCases({
        order: 'desc',
        rows: 999,
        sort: 'todo'
      }),
    select: ({ status, cases }) => (status === 204 ? [] : cases || [])
  });
  const noCases = allCases.length === 0;

  const { data: todo = [] } = useQuery({
    queryKey: ['getNextSteps'],
    queryFn: () => dispatch(actions.getIncompletedToDosList()),
    select: (data) => data.todo
  });

  const todoCount = todo.length;
  const noTodos = todo.length === 0;
  const todoBadgeText = todoCount >= 10 ? '9+' : todoCount.toString();
  // If at least one case & at least one todo, then show badge on todos page
  const todoBadgeContent = noCases || noTodos ? null : todoBadgeText;
  // If no cases but there are todos, then show badge on home page
  //      They will be patient level, so inaccessible on todos page
  const homeBadgeContent = !noTodos && noCases ? todoBadgeText : null;

  const menuItems = [
    {
      name: 'Home',
      action: () => router.push('/patients'),
      active: pathname === '/patients',
      testid: 'patients-dashboard-link',
      Icon: HomeFilled,
      badge: homeBadgeContent
    },
    {
      name: t('To-do list'),
      action: () => router.push('/patients/todos'),
      active: pathname === '/patients/todos',
      restricted: true,
      testid: 'patients-todos-link',
      Icon: CarryOutFilled,
      badge: todoBadgeContent
    },
    {
      name: 'Appointments',
      action: () => router.push('/patients/appointments'),
      active:
        pathname === '/patients/appointments' ||
        pathname.includes('/patients/timeline') ||
        pathname.includes('/patients/session') ||
        pathname.includes('/patients/choose'),
      restricted: true,
      testid: 'patients-appointments-link',
      Icon: CalendarFilled
    },
    {
      name: 'Documents',
      action: () => router.push('/patients/documents'),
      active: pathname === '/patients/documents',
      testid: 'patients-documents-link',
      restricted: true,
      Icon: ContainerFilled
    },
    {
      name: 'Support',
      action: () => router.push('/patients/support'),
      active: pathname === '/patients/support',
      testid: 'patients-support-link',
      Icon: QuestionCircleFilled
    }
  ];

  if (isExtremeZoom) {
    return (
      <Dropdown
        aria-label="Navigation menu"
        menu={{
          items: menuItems.map((item) => ({
            key: item.name,
            label: item.name,
            icon: <item.Icon />,
            onClick: item.restricted && isRestricted ? () => setPleaseLogIn(true) : item.action,
            'data-testid': item.testid
          }))
        }}
        arrow
        trigger={['click']}
        placement="top"
        onOpenChange={setHamburgerMenuExpanded}
      >
        <a
          role="button"
          aria-expanded={isHamburgerMenuExpanded}
          aria-label="hamburger"
          onClick={(e) => e.preventDefault()}
          href="#"
          data-testid="hamburger-menu"
        >
          <MenuOutlined />
        </a>
      </Dropdown>
    );
  }

  return (
    <StyledHeader>
      <div className="button-container">
        {menuItems.map((item) => (
          <MenuButton key={item.name} {...item} data-testid={item.testid} />
        ))}
      </div>
    </StyledHeader>
  );
};
